











import { Component, Prop, Vue } from 'vue-property-decorator'
import { TranslateResult } from 'vue-i18n'

import { benefitsBarClassRegistry, BenefitsBarProps } from './BenefitsBar.contracts'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 */
@Component({ name: 'BenefitsBar' })
export class BenefitsBar extends Vue {
  @Prop({ type: String, required: false, default: '' })
  private theme!: BenefitsBarProps['theme']

  /**
   * Use BenefitsBar Theme
   */
  public get themeClass (): string {
    if (!(this.theme in benefitsBarClassRegistry)) {
      return ''
    }

    return benefitsBarClassRegistry[this.theme]
  }

  /**
   * Use translations
   */
  public get benefits (): TranslateResult {
    return this.$t('front.shared.benefits')
  }
}

export default BenefitsBar
