






























import { Component, Watch } from 'vue-property-decorator'
import { UiMarkdown } from '../../../partials/UiMarkdown'
import { AbstractHeroAddon } from './abstract'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<TimerAddon>({
  name: 'TimerAddon',
  components: { UiMarkdown },
  mounted (): void {
    this.initTimer()

    if (this.showTimer) {
      this.interval = setInterval(() => {
        this.setCountdownData()
      }, 1000)
    }
  },

  destroyed (): void {
    if (this.interval) {
      clearInterval(this.interval)
      this.interval = null
    }
  }
})
export class TimerAddon extends AbstractHeroAddon {
    public timer: any | null = null
    public limitTime: number | null = null
    public visible = true

    // eslint-disable-next-line no-undef
    protected interval: NodeJS.Timeout | null = null

    public get hasText (): boolean {
      return this.moduleData.content.text !== 'undefined' && this.moduleData.content.text.length > 0
    }

    public setCountdownData (): void {
      if (!this.limitTime) {
        return
      }

      const now = Date.now()
      const remainingTime = (this.limitTime - now) / 1000

      this.timer = {
        days: {
          value: Math.floor(remainingTime / 60 / 60 / 24),
          unit: 'dni'
        },
        hours: {
          value: Math.floor((remainingTime / 60 / 60) % 24),
          unit: 'godz.'
        },
        minutes: {
          value: Math.floor((remainingTime / 60) % 60),
          unit: 'min.'
        },
        seconds: {
          value: Math.floor(remainingTime % 60),
          unit: 'sek.'
        }
      }
    }

    public get showTimer (): boolean {
      if (!this.limitTime) {
        return false
      }

      return new Date().getTime() < this.limitTime
    }

    public pad (value: number): string {
      return (`0${value}`).slice(-2)
    }

    public initTimer (): void {
      const dueDateString = this.parseDate(this.moduleData.content.dueDate)
      const dueDate = new Date(dueDateString)

      this.limitTime = dueDate.getTime()
      this.setCountdownData()
    }

    @Watch('timer', { deep: true })
    protected onTimer (value: any): void {
      if (value.hours === 0 && value.minutes === 0 && value.seconds === 0) {
        this.visible = false
        // TODO: What to do after timer is over ?
      }
    }

    private parseDate (dateString: string): Date {
      const dateParts = dateString.split(' ')
      const date = dateParts[0]
      const time = dateParts[1]

      const [year, month, day] = date.split('-').map(Number)
      const [hours, minutes, seconds] = time.split(':').map(Number)

      return new Date(year, month - 1, day, hours, minutes, seconds)
    }
}

export default TimerAddon
