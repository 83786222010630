




















import { AnyObject } from '@movecloser/front-core'
import { Component } from 'vue-property-decorator'

import { ImageProps } from '../../../dsl/atoms/Image'
import { toImageProps } from '../../../front/shared/support'

import { AbstractModuleUi } from '../../abstract/ui'

import { ImageModule } from '../Image.contracts'
import { AllowedImageRatio } from '../../../contexts'
import { imageContentFactory } from '../Image.factory'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<ImageModuleUi>({
  name: 'ImageModuleUi'
})
export class ImageModuleUi extends AbstractModuleUi<ImageModule> {
  protected defaultContent = imageContentFactory()

  public get alignmentClass (): string | null {
    if (!this.style.justifySelf) {
      return null
    }

    return `ImageModuleUi--justify-${this.style.justifySelf}`
  }

  /**
   * Determines whether the component has all the data it needs for a successful render.
   */
  public get shouldRender (): boolean {
    return this.hasContent && !!this.content.image
  }

  /**
   * Determines whether module has customClasses
   */
  public get customClass (): Array<string> {
    if (!this.content) {
      return []
    }

    if (!this.content.customClass || this.content.customClass.length === 0) {
      return []
    }

    return this.content.customClass
  }

  /**
   * Constructs image props with respect of overridden values.
   */
  public get image (): ImageProps {
    if (!this.content.image) {
      throw new Error('ImageModuleUi(): Image is [null]')
    }

    const translatedImage: ImageProps = toImageProps(this.content.image, AllowedImageRatio.Original, this.content.maxWidth)

    const { alt, caption, title, maxHeight, maxWidth, preventLazyload } = { ...this.content }

    return {
      ...translatedImage,
      alt: alt ?? '',
      caption,
      title,
      maxHeight,
      maxWidth,
      isLazy: !preventLazyload
    }
  }

  /**
   * Assigns styles based on passed-in props.
   *
   * @example: (max-width, max-height)
   */
  public get sizeStyle (): AnyObject {
    const sizeStyle: AnyObject = {}

    if (typeof this.content.maxHeight !== 'undefined') {
      sizeStyle.maxHeight = `${this.content.maxHeight}px`
    }

    if (typeof this.content.maxWidth !== 'undefined') {
      sizeStyle.maxWidth = `${this.content.maxWidth}px`
    }

    return sizeStyle
  }
}

export default ImageModuleUi
