
























































import { Component, Inject, Ref, Vue, Watch } from 'vue-property-decorator'

import { ProductSneakPeakData, VariantSneakPeak } from '../../../contexts'

import { defaultProvider, IS_MOBILE_PROVIDER_KEY } from '../../../support'

import { ImageProps } from '../../../dsl/atoms/Image'

import { generateImagePlaceholder } from '../../../front/shared/support/image-placeholder'
import { ProductCard } from '../../../front/products/organisms/ProductCard'
import { ProductCardProps } from '../../../front/products/organisms/ProductCard/ProductCard.contracts'
import { toImageProps } from '../../../front/shared/support'
import { translateProductToProductCard } from '../../../front/products/organisms/ProductCard/ProductCard.helpers'

import { AbstractModuleUi } from '../../abstract/ui'

import { CreationsModule, PointCoordinate } from '../Creations.contracts'
import { creationsCarouselConfig } from '../Creations.config'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component<CreationsModuleUi>({
  name: 'CreationsModuleUi',
  components: { ProductCard }
})
export class CreationsModuleUi extends AbstractModuleUi<CreationsModule> {
  /**
   * Determines whether the app is running on a mobile device.
   */
  @Inject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobileDevice!: () => boolean

  /**
   * Creations image ref.
   */
  @Ref('creationsImage')
  public readonly creationsImageRef?: Vue

  /**
   * Creations carousel config
   */
  public readonly carouselConfig = creationsCarouselConfig

  /**
   * Determines creations fallback image.
   */
  public readonly fallbackImage = generateImagePlaceholder('small').src

  /**
   * Determines active point.
   */
  public activePointId: string | null = null

  /**
   * Determines creations image.
   */
  public get image (): ImageProps | undefined {
    const image = this.content.image
    if (!image) {
      return {
        src: '',
        alt: ''
      }
    }

    return toImageProps(image)
  }

  /**
   * Determines creations points.
   */
  public get points (): PointCoordinate[] | undefined {
    const points = this.content.pointsCoordinates
    if (!points) {
      return
    }

    return points
  }

  public getPointStyles (x: number, y: number) {
    return { left: `${x}%`, top: `${y}%` }
  }

  public getVariant (product: ProductSneakPeakData): VariantSneakPeak<string> {
    return product.variants[0]
  }

  public hideProduct (): void {
    this.activePointId = null
  }

  public isActivePoint (id: number): boolean {
    return this.activePointId === `point-${id}`
  }

  public pointImage (product: ProductSneakPeakData): ImageProps | undefined {
    const firstVariant = this.getVariant(product)
    let image = generateImagePlaceholder('small')

    if (firstVariant.images.length > 0) {
      image = toImageProps(firstVariant.images[0])
    }

    return image
  }

  public productCardProps (product: ProductSneakPeakData): ProductCardProps {
    return translateProductToProductCard(product)
  }

  public onMouseLeave (): void {
    if (this.isMobileDevice()) {
      return
    }

    this.hideProduct()
  }

  public showProduct (id: number): void {
    this.activePointId = `point-${id}`
  }

  @Watch('activePointId')
  protected onActivePointChange () {
    this.$emit('onActivePointChange', this.activePointId)
  }
}

export default CreationsModuleUi
